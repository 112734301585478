<template>
	<div>
		<div class="flex items-center mb-5 mt-5">
			<h1 class="w-4/12 text-xl h-6 font-extrabold sm:text-2xl">Медицинские организации</h1>
			<div class="flex justify-end w-full items-center">
				<!-- <Button v-if="userIsInRoles(['Support', 'MZ_Employee'])" class="mr-2" text="Добавить МО" icon="add" @click.native="openPopUp()" /> -->
				<Button text="Экспорт в Excel" icon="exel" @click.native="openExport = true" />
			</div>
		</div>
		<div class="flex flex-col relative">
			<div
				class="t-overflow rounded-t-lg overflow-x-auto h-auto bg-white filter shadow-default"
				:class="{ 'custom-w-table-144': !menu, 'custom-w-table-360': menu }"
				:style="medOrgs.length > 9 ? 'height: calc(100vh - 316px)' : ''"
			>
				<table :style="medOrgs.length > 0 ? 'min-height: 580px' : ''">
					<thead>
						<tr :class="{ tr1__sticky: rowsVisible > 5 }">
							<th
								style="min-width: 50px; max-width: 50px"
								class="sticky-table__header2 text-center border-b border-gray-200 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
							>
								П/П
							</th>
							<th
								class="w-full px-6 py-4 border-b border-gray-200 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
								v-for="(item, ind) in fields"
								:class="{ 'sticky-table__header min-w-min': ind === 0, 'row-w': ind !== 0 && ind !== fields.length - 1 }"
								:key="'J' + ind"
							>
								<span class="flex relative">
									<span class="font-semibold border-b border-gray-200 cursor-pointer" :class="{ 'text-blue-500 underline': filterBy === ind }" @click="switchFilterVisibility(ind)">
										{{ item.Name }}
									</span>
									<Icons v-if="params.filters.find((f) => f.fieldName === item.fieldName)" icon-name="filter" class="hidden sm:block ml-2" />

									<!-- Фильтр и сортировка -->
									<FilterComponent
										v-on:change="switchFilterVisibility(null)"
										:position="ind === 0 ? 'left' : 'right'"
										:filterOpen="filterOpen"
										:id-filter="ind"
										:param="params"
										@query="getData"
										:fields="fields"
										:defaultSortField="defaultSort.field"
										:defaultSortOrder="defaultSort.order"
										:item="item"
									/>
								</span>
							</th>
						</tr>
					</thead>
					<tbody class="bg-white" v-for="(item, index) in medOrgs" :key="'G' + index">
						<tr>
							<td class="sticky-table__row2 px-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500 bg-white">
								<span v-if="params.currentPage === 0">
									{{ index + 1 }}
								</span>
								<span v-if="params.currentPage > 0">
									{{ params.currentPage * rowsVisible + (index + 1) }}
								</span>
							</td>
							<td class="sticky-table__row px-6 py-4 text-sm whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500 bg-white">
								<div class="flex items-center mb-2">
									<div v-if="item.isBranch" class="filial-or-secondary-default filial-bg">Филиал</div>
									<div v-if="item.isClone" class="filial-or-secondary-default secondary-bg ml-2">Вторичная МО</div>
								</div>
								<div class="flex items-center">
									<Icons v-if="userIsInRoles(['Support', 'MZ_Employee'])" class="icon-control mr-2" icon-name="pen" @click.native.stop="openEditDialog(item)" title="Редактировать" />
									<Icons
										v-if="userIsInRoles(['Support', 'MZ_Employee'])"
										class="icon-control icon-danger mr-2"
										icon-name="trash"
										@click.native.stop="openDeleteDialog(item, index)"
										title="Удалить"
									/>
									<span @click="openRow(item)" :class="{ 'underline tex-blue-400 cursor-pointer': item.hasBranch }">
										{{ item.name }}
									</span>
								</div>
							</td>
							<td class="row-w-2 px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
								<span>{{ item.shortName ? item.shortName : '---' }}</span>
							</td>
							<td class="row-w px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
								<span>{{ item.regionId ? setName(item.regionId, 'regions') : '---' }}</span>
							</td>
							<td class="row-w px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
								<span>{{ item.address ? item.address : '---' }}</span>
							</td>
							<td class="row-w px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
								<span>{{ item.postAddress ? item.postAddress : '---' }}</span>
							</td>
							<td class="row-w px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
								<span>{{ item.ownerShipId ? setName(item.ownerShipId, 'ownership') : '---' }}</span>
							</td>
							<td class="row-w px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
								<span>{{ item.inn ? item.inn : '---' }}</span>
							</td>
							<td class="row-w px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
								<span>{{ item.webSiteAddress ? item.webSiteAddress : '---' }}</span>
							</td>
							<td class="row-w px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
								<span>{{ item.departmentBelongId ? setName(item.departmentBelongId, 'departments') : '---' }}</span>
							</td>
							<td class="row-w px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
								<span>{{ item.nomenclatureId ? setName(item.nomenclatureId, 'nomenclatures') : '---' }}</span>
							</td>
							<td class="row-w px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
								<span>{{ item.noteName }}</span>
							</td>
							<td class="row-w px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
								<span v-if="item.years">
									{{
										item.years
											.map((item) => {
												return item.year;
											})
											.join(', ')
									}}
								</span>
								<span v-else> - </span>
							</td>
							<td class="row-w px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
								<span>{{ item.ambulator ? 'Да' : 'Нет' }}</span>
							</td>
							<td class="row-w px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
								<span>{{ item.stacionar ? 'Да' : 'Нет' }}</span>
							</td>
							<td class="row-w px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
								<span>{{ item.healthResort ? 'Да' : 'Нет' }}</span>
							</td>
							<td class="row-w px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
								<span>{{ item.psychiatric ? 'Да' : 'Нет' }}</span>
							</td>
							<td class="row-w px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
								<span v-if="item.noteId === Note.Participation || item.noteId === Note.Federal">
									<span class="underline text-blue-500 cursor-pointer" @click="openBunnersPopUp(item)"> Получить баннер </span>
								</span>
								<span v-else> - </span>
							</td>
							<td class="px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
								<span v-if="item.noteId === Note.Participation || item.noteId === Note.Federal">
									<span class="underline text-blue-500 cursor-pointer" @click="downloadQr(item)"> Скачать </span>
								</span>
								<span v-else> - </span>
							</td>
						</tr>
						<tr v-if="item.id === opened">
							<td :colspan="fields.length + 1">
								<table class="w-full">
									<tbody>
										<tr v-for="(it, ind1) in item.branches" :key="'U' + ind1" :class="getRowClass(it, item.id === opened, false)">
											<td
												class="sticky-table__row2 px-5 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500"
												:class="getRowClass(item, item.id === opened, true)"
											>
												<span> {{ ind1 + 1 }} </span>
											</td>
											<td
												class="sticky-table__row px-6 py-4 text-sm whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500"
												:class="getRowClass(it, item.id === opened, true)"
											>
												<div class="flex items-center mb-2">
													<div v-if="it.isBranch" class="filial-or-secondary-default filial-bg">Филиал</div>
													<div v-if="it.isClone" class="filial-or-secondary-default secondary-bg ml-2">Вторичная МО</div>
												</div>
												<div class="flex items-center">
													<Icons
														v-if="userIsInRoles(['Support', 'MZ_Employee']) && !it.isClone"
														class="mr-2 cursor-pointer"
														icon-name="pen"
														@click.native.stop="openEditDialog(it)"
														title="Редактировать"
													/>
													<Icons
														v-if="userIsInRoles(['Support', 'MZ_Employee'])"
														class="icon-control icon-danger mr-2"
														icon-name="trash"
														@click.native.stop="openDeleteDialog(it, ind1)"
														title="Удалить"
													/>
													<span>{{ it.name }}</span>
												</div>
											</td>
											<td class="row-w-2 px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
												<span>{{ it.shortName ? it.shortName : '---' }}</span>
											</td>
											<td class="row-w px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
												<span>{{ it.regionId ? setName(it.regionId, 'regions') : '---' }}</span>
											</td>
											<td class="row-w px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
												<span>{{ it.address ? it.address : '---' }}</span>
											</td>
											<td class="row-w px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
												<span>{{ it.postAddress ? it.postAddress : '---' }}</span>
											</td>
											<td class="row-w px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
												<span>{{ it.ownerShipId ? setName(it.ownerShipId, 'ownership') : '---' }}</span>
											</td>
											<td class="row-w px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
												<span>{{ it.inn ? it.inn : '---' }}</span>
											</td>
											<td class="row-w px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
												<span>{{ it.webSiteAddress ? it.webSiteAddress : '---' }}</span>
											</td>
											<td class="row-w px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
												<span>{{ it.departmentBelongId ? setName(it.departmentBelongId, 'departments') : '---' }}</span>
											</td>
											<td class="row-w px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
												<span>{{ it.nomenclatureId ? setName(it.nomenclatureId, 'nomenclatures') : '---' }}</span>
											</td>
											<td class="row-w px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
												<span>{{ it.noteName }}</span>
											</td>
											<td class="row-w px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
												<span v-if="it.years">
													{{
														it.years
															.map((it) => {
																return it.year;
															})
															.join(', ')
													}}
												</span>
												<span v-else> - </span>
											</td>
											<td class="row-w px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
												<span>{{ it.ambulator ? 'Да' : 'Нет' }}</span>
											</td>
											<td class="row-w px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
												<span>{{ it.stacionar ? 'Да' : 'Нет' }}</span>
											</td>
											<td class="row-w px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
												<span>{{ it.healthResort ? 'Да' : 'Нет' }}</span>
											</td>
											<td class="row-w px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
												<span>{{ it.psychiatric ? 'Да' : 'Нет' }}</span>
											</td>
											<td class="row-w px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
												<span v-if="it.noteId === Note.Participation || it.noteId === Note.Federal || !it.isClone">
													<span class="underline text-blue-500 cursor-pointer" @click="openBunnersPopUp(it)"> Получить баннер </span>
												</span>
												<span v-else> - </span>
											</td>
											<td class="px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
												<span v-if="it.noteId === Note.Participation || it.noteId === Note.Federal || !it.isClone">
													<span class="underline text-blue-500 cursor-pointer" @click="downloadQr(it)"> Скачать </span>
												</span>
												<span v-else> - </span>
											</td>
										</tr>
									</tbody>
								</table>
							</td>
						</tr>
					</tbody>
				</table>
				<div v-if="!loadingTable && medOrgs.length === 0" class="flex items-center w-full h-96 whitespace-no-wrap text-xl leading-5 text-gray-500 text-center">
					<span class="w-full">Данных нет...</span>
				</div>
			</div>
			<!-- Навигация -->
			<div class="h-auto rounded-b-lg bg-white filter shadow-default">
				<div class="hidden sm:flex flex-row pt-6 pl-6 pr-20 pb-12 text-gray-400 text-sm">
					<div class="mr-auto">
						<p>Всего записей: {{ totalRows }}</p>
					</div>
					<div class="flex flex-row ml-auto">
						<p>Строк на странице:</p>
						<ul class="flex flex-row">
							<li @click="updateRow(50)" class="flex ml-4 cursor-pointer" :class="{ 'text-blue-500': rowsVisible == 50 }">50</li>
							<li @click="updateRow(100)" class="flex ml-4 cursor-pointer" :class="{ 'text-blue-500': rowsVisible == 100 }">100</li>
							<li @click="updateRow(200)" class="flex ml-4 cursor-pointer" :class="{ 'text-blue-500': rowsVisible == 200 }">200</li>
							<li @click="updateRow(null)" class="flex ml-4 cursor-pointer" :class="{ 'text-blue-500': rowsVisible == null }">Все</li>
						</ul>
					</div>
					<div class="flex flex-row ml-24">
						<ul class="flex flex-row">
							<li class="flex ml-4 cursor-pointer arrow" @click="page !== 0 ? navigation('params', page - 1) : ''" />
							<li class="flex">
								<span>Стр</span>
								<b class="ml-2">{{ page + 1 }}</b>
								<span class="ml-2">из</span>
								<b class="ml-2">{{ totalCount === 0 ? 1 : totalCount }}</b>
							</li>
							<li class="flex ml-2 cursor-pointer arrow" @click="page + 1 < totalCount ? navigation('params', page + 1) : ''" />
						</ul>
					</div>
				</div>
			</div>

			<div v-if="loadingTable" class="absolute w-full h-full preloader rounded-lg">
				<loadingComponent class="absolute top-1/2 left-1/2" />
			</div>
		</div>

		<PopUp :open="open" :title="popupTitle" size="1000px" @close="closePopup()">
			<div class="flex w-full gap-5">
				<div class="w-6/12">
					<div class="input-box" :class="{ error: error.regionId }">
						<label>Регион*</label>
						<v-select
							@input="changeRegion(infoMO.regionId, true)"
							:options="regions"
							:disabled="innEmpty || infoMO.isClone"
							v-model="infoMO.regionId"
							label="name"
							:reduce="(option) => option.value"
						/>
						<p class="error-text" v-if="error.regionId">{{ error.regionId }}</p>
					</div>
					<TextInput label="Краткое наименование МО*" :title="infoMO.shortName" :disabled="innEmpty || infoMO.isClone" v-model="infoMO.shortName" :error="error.shortName" />
					<TextInput label="Почтовый адрес*" :title="infoMO.postAddress" :disabled="innEmpty || infoMO.isClone" v-model="infoMO.postAddress" :error="error.postAddress" />
					<TextInput label="ИНН*" :title="infoMO.inn" @input="setInn(infoMO.inn)" type="number" v-model="infoMO.inn" :error="error.inn" />
					<div class="input-box" v-if="userIsInRoles(['Support', 'MZ_Employee'])">
						<label>Ведомственная принадлежность</label>
						<v-select :options="departments" :disabled="innEmpty || infoMO.isClone" label="name" v-model="infoMO.departmentBelongId" :reduce="(option) => option.value" />
					</div>
					<div class="input-box" v-if="yearsAreAvailable(infoMO.noteId)">
						<label class="select-label"> Включено в перечень </label>
						<div class="relative">
							<multiselect
								tag-placeholder=""
								:disabled="innEmpty || !infoMO.isMain"
								@change="setYears(infoMO.years)"
								placeholder=""
								:hide-selected="true"
								:loading="loadingYears"
								:searchable="false"
								:multiple="true"
								:options="optionsYears"
								label="year"
								track-by="year"
								v-model="infoMO.years"
							>
							</multiselect>

							<div v-if="loadingYears" class="absolute w-full h-full preloader-years rounded-lg">
								<loadingComponent class="absolute top-1/4 left-1/2" />
							</div>
						</div>
					</div>
					<div class="flex justify-start mt-4" v-if="infoMO.noteId === Note.Participation || infoMO.noteId === Note.Federal">
						<div class="radio-group" style="width: 240px">
							<p class="select-label">Амбулаторные*</p>
							<div class="flex items-center">
								<input type="radio" name="ambulator" :disabled="innEmpty || infoMO.isClone" :value="true" v-model="infoMO.ambulator" />
								<label class="field-value">Да</label>
								<input type="radio" name="ambulator" :disabled="innEmpty || infoMO.isClone" :value="false" v-model="infoMO.ambulator" class="ml-2" />
								<label class="field-value">Нет</label>
							</div>
						</div>
						<div class="radio-group">
							<p class="select-label">Стационарные*</p>
							<div class="flex items-center">
								<input type="radio" name="stacionar" :disabled="innEmpty || infoMO.isClone" :value="true" v-model="infoMO.stacionar" />
								<label class="field-value">Да</label>
								<input type="radio" name="stacionar" :disabled="innEmpty || infoMO.isClone" :value="false" v-model="infoMO.stacionar" class="ml-2" />
								<label class="field-value">Нет</label>
							</div>
						</div>
					</div>
					<div class="flex justify-start" v-if="infoMO.noteId === Note.Participation || infoMO.noteId === Note.Federal">
						<div class="radio-group" style="width: 240px">
							<p class="select-label">Психиатрические*</p>
							<div class="flex items-center">
								<input type="radio" name="psychiatric" :disabled="innEmpty || infoMO.isClone" :value="true" v-model="infoMO.psychiatric" />
								<label class="field-value">Да</label>
								<input type="radio" name="psychiatric" :disabled="innEmpty || infoMO.isClone" :value="false" v-model="infoMO.psychiatric" class="ml-2" />
								<label class="field-value">Нет</label>
							</div>
						</div>
						<div class="radio-group">
							<p class="select-label">Санаторно-курортные*</p>
							<div class="flex items-center">
								<input type="radio" name="healthResort" :disabled="innEmpty || infoMO.isClone" :value="true" v-model="infoMO.healthResort" />
								<label class="field-value">Да</label>
								<input type="radio" name="healthResort" :disabled="innEmpty || infoMO.isClone" :value="false" v-model="infoMO.healthResort" class="ml-2" />
								<label class="field-value">Нет</label>
							</div>
						</div>
						<!-- <RadioGroup label="Психиатрические*" :option="['Да', 'Нет']"/>
						<RadioGroup label="Санаторно-курортные*" :option="['Да', 'Нет']"/> -->
					</div>
					<div v-if="(infoMO.noteId === Note.Participation || infoMO.noteId === Note.Federal) && error.conditions" class="error-text">
						{{ error.conditions }}
					</div>
				</div>
				<div class="w-6/12">
					<TextInput label="Полное наименование МО*" :title="infoMO.name" :disabled="innEmpty || infoMO.isClone" v-model="infoMO.name" :error="error.name" />
					<TextInput :label="'Фактический адрес' + (!infoMO.isClone ? '*' : '')" :title="infoMO.address" :disabled="innEmpty" v-model="infoMO.address" :error="error.address" />
					<div class="input-box" :class="{ error: error.ownerShipId }">
						<label>Форма собственности*</label>
						<v-select :options="ownership" :disabled="innEmpty || infoMO.isClone" v-model="infoMO.ownerShipId" label="name" taggable :reduce="(option) => option.value" />
						<p class="error-text" v-if="error.ownerShipId">{{ error.ownerShipId }}</p>
					</div>
					<TextInput label="Адрес официального сайта*" :disabled="innEmpty || infoMO.isClone" v-model="infoMO.webSiteAddress" :error="error.webSiteAddress" />
					<div class="input-box" :class="{ error: error.nomenclatureId }">
						<label>Номенклатура*</label>
						<v-select
							:options="nomenclatures"
							:disabled="innEmpty || infoMO.isClone"
							@input="notesChange(infoMO.nomenclatureId)"
							v-model="infoMO.nomenclatureId"
							label="name"
							taggable
							:reduce="(option) => option.value"
						/>
						<p class="error-text" v-if="error.nomenclatureId">{{ error.nomenclatureId }}</p>
					</div>
					<div class="input-box" :class="{ error: error.noteId }">
						<label>Примечание*</label>
						<v-select :options="notes" :disabled="innEmpty || !infoMO.isMain || noteDisabled" v-model="infoMO.noteId" label="name" :reduce="(option) => option.value" />
						<p class="error-text" v-if="error.noteId">{{ error.noteId }}</p>
					</div>
					<div class="static-row mt-4">
						<label>Тип МО: </label>
						<span class="field-value">{{ infoMO.typeName }}</span>
					</div>
					<div v-if="infoMO.parentName != null" class="static-row">
						<label>Головная организация: </label>
						<span class="field-value">{{ infoMO.parentName }}</span>
					</div>
				</div>
			</div>
			<div class="flex mt-5">
				<Button size="medium" class="mr-3" color="white" text="Отмена" @click.native="closePopup()" />
				<Button size="medium" text="Сохранить" @click.native="sendForm()" />
			</div>
		</PopUp>
		<PopUp :open="openBunners" size="95%" title="Получить баннер" @close="closeBunners()">
			<div class="flex justify-between">
				<Bunner :ids="bunnersId" level="mo" type="1"></Bunner>
				<Bunner :ids="bunnersId" level="mo" type="2"></Bunner>
				<Bunner :ids="bunnersId" level="mo" type="3"></Bunner>
			</div>
		</PopUp>
		<PopUp :open="branchDialog" @close="setBranch(true)">
			<p>
				МО с таким ИНН уже существует. Создать еще одно МО с тем же ИНН можно в двух случаях: если оно является филиалом медицинского учреждения, то выберите ниже вариант «Филиал», если
				учреждение обслуживает пациентов из другого региона (отличного от региона, в котором находится учреждение), то выберите вариант «МО обслуживает другой регион»
			</p>
			<div class="flex mt-5">
				<Button size="medium" class="mr-3" text="Филиал" @click.native="setBranch(true)" />
				<Button size="medium" text="МО обслуживает другой регион" @click.native="setBranch(false)" />
			</div>
		</PopUp>
		<PopUp :open="headExistsDialog" @close="headExistsDialog = false">
			<p>В Системе уже существует головная организация с таким ИНН.</p>
			<div class="flex mt-5">
				<Button size="smoll" color="white" text="Закрыть" @click.native="headExistsDialog = false" />
			</div>
		</PopUp>
		<PopUp :open="openExport" size="500px" title="Выберите поля для экспорта" @close="closeOpenExport">
			<div class="flex flex-col">
				<CheckboxGroup :options="fieldsDownloadExcell" :disabled="false" />
				<div class="flex mt-5 justify-end">
					<Button size="smoll" class="mr-3" text="Экспорт" :load="loadToExcell" :disabled="loadToExcell" @click.native="downloadExcell(params, fieldsDownloadExcell)" />
					<Button size="smoll" color="white" text="Закрыть" @click.native="closeOpenExport()" />
				</div>
			</div>
		</PopUp>
	</div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import Icons from '@/components/Icons.vue';
import PopUp from '@/components/PopUp.vue';
import FilterComponent from '@/components/Filter.vue';
import Button from '@/components/Button.vue';
import CheckboxGroup from '@/components/CheckboxGroup.vue';
import loadingComponent from '../../../../components/Loading.vue';
import Bunner from '@/components/Bunner.vue';
import TextInput from '@/components/TextInput.vue';
// Глобальные данные стора
import { getters, mutations, actions, methods, compareAsc } from '@/store/store';
import { Note } from '@/store/enumerations';
// Локальные данные стора
import actionsLocal from '../store/actions';

export default {
	name: 'TableMain',
	components: {
		Icons,
		Bunner,
		Multiselect,
		Button,
		PopUp,
		TextInput,
		loadingComponent,
		FilterComponent,
		CheckboxGroup
	},
	data() {
		return {
			loadingYears: true,
			openExport: false,
			loadingTable: false,
			open: false,
			branchDialog: false,
			headExistsDialog: false,
			openBunners: false,
			bunnersId: '',
			rowsVisible: this.$DefaultPageSize,
			totalRows: 0,
			totalCount: 0,
			page: 0,
			regions: [],
			infoMO: {},
			originalMO: null,
			error: {},
			filterOpen: null,
			filterBy: null,
			opened: null,
			fields: [
				{
					Name: 'Полное наименование МО',
					fieldName: 'name',
					filterType: 2,
					filterValueType: 1,
					autocomplete: true,
					timeout: 3,
					placeholder: 'Введите текст, более 2 символов',
					list: [],
					widthStroke: 'w-244',
					heightUl: '150px',
					itemKey: 'name',
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию (А-Я)',
					label2: 'По убыванию (Я-А)'
				},
				{
					Name: 'Краткое наименование МО',
					fieldName: 'shortName',
					filterType: 2,
					filterValueType: 1,
					autocomplete: true,
					timeout: 4,
					list: [],
					widthStroke: 'w-96',
					heightUl: '150px',
					itemKey: 'shortName',
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Регион',
					fieldName: 'RegionName',
					filterType: 2,
					filterValueType: 1,
					autocomplete: true,
					list: [],
					widthStroke: 'w-96',
					heightUl: '150px',
					itemKey: 'Name',
					value1: '',
					value2: null,
					sortBy: 2,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Фактический адрес',
					fieldName: 'address',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Почтовый адрес',
					fieldName: 'postAddress',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Форма собственности',
					fieldName: 'OwnerShipName',
					filterType: 2,
					filterValueType: 1,
					autocomplete: true,
					list: [],
					heightUl: '150px',
					itemKey: 'name',
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'ИНН',
					fieldName: 'inn',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Адрес официального сайта',
					fieldName: 'webSiteAddress',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Ведомственная принадлежность',
					fieldName: 'DepartmentBelongName',
					filterType: 2,
					filterValueType: 1,
					autocomplete: true,
					list: [],
					widthStroke: 'w-96',
					heightUl: '150px',
					itemKey: 'name',
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Номенклатура',
					fieldName: 'NomenclatureName',
					filterType: 2,
					filterValueType: 1,
					autocomplete: true,
					list: [],
					widthStroke: 'w-96',
					heightUl: '150px',
					itemKey: 'name',
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Примечание',
					fieldName: 'NoteName',
					filterType: 2,
					filterValueType: 1,
					autocomplete: true,
					list: [],
					widthStroke: 'w-96',
					heightUl: '150px',
					itemKey: 'name',
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Включено в перечень',
					fieldName: 'YearStr',
					filterType: 9,
					filterValueType: 1,
					value1: null,
					value2: null,
					values: [],
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Амбулаторные',
					fieldName: 'ambulator',
					filterType: 1,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Стационарные',
					fieldName: 'stacionar',
					filterType: 1,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Санаторно-курортные',
					fieldName: 'healthResort',
					filterType: 1,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Психиатрические',
					fieldName: 'psychiatric',
					filterType: 1,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Баннер',
					filterType: 0,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'QR-код',
					filterType: 0,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				}
			],
			fieldsDownloadExcell: [
				{
					title: 'Регион',
					id: 'Region',
					checked: true
				},
				{
					title: 'Филиал',
					id: 'Branch',
					checked: true
				},
				{
					title: 'Полное наименование МО',
					id: 'Name',
					checked: true
				},
				{
					title: 'Краткое наименование МО',
					id: 'ShortName',
					checked: true
				},
				{
					title: 'Фактический адрес',
					id: 'Address',
					checked: true
				},
				{
					title: 'Почтовый адрес',
					id: 'PostAddress',
					checked: true
				},
				{
					title: 'Форма собственности',
					id: 'OwnershipForm',
					checked: true
				},
				{
					title: 'ИНН',
					id: 'INN',
					checked: true
				},
				{
					title: 'Адрес официального сайта',
					id: 'WebsiteAddress',
					checked: true
				},
				{
					title: 'Ведомственная принадлежность',
					id: 'Department',
					checked: true
				},
				{
					title: 'Номенклатура',
					id: 'Nomenclature',
					checked: true
				},
				{
					title: 'Примечание',
					id: 'Note',
					checked: true
				},
				{
					title: 'Включено в перечень',
					id: 'Year',
					checked: true
				},
				{
					title: 'Амбулаторные',
					id: 'Ambulatory',
					checked: true
				},
				{
					title: 'Стационарные',
					id: 'Stationary',
					checked: true
				},
				{
					title: 'Санаторно-курортные',
					id: 'Sanatorium',
					checked: true
				},
				{
					title: 'Психиатрические',
					id: 'Psychiatric',
					checked: true
				}
			],
			allRows: [],
			medOrgs: [],
			optionsYears: [],
			children: [],
			update: false,
			popupTitle: '',
			defaultSort: {
				field: 'NoteName',
				order: 1
			},
			params: {
				filters: [],
				sortOption: {
					fieldName: '',
					sortType: 2
				},
				pageSize: this.$MaxPageSize,
				currentPage: 0
			},
			loadToExcell: false,
			departments: [],
			nomenclatures: [],
			notes: [],
			ownership: [],
			filterData: {
				sortBy: '',
				searchValue: ''
			},
			noteDisabled: true,
			innEmpty: true,
			defNomenclatures: [
				'Женская консультация',
				'Молочная кухня',
				'Станция скорой медицинской помощи',
				'Станция переливания крови (центр крови)',
				'Дом ребенка, в том числе специализированный',
				'Физиотерапевтическая поликлиника',
				'Центр медицинской и социальной реабилитации, в том числе с отделением постоянного проживания инвалидов и детей-инвалидов с тяжелыми формами детского церебрального паралича, самостоятельно не передвигающихся и себя не обслуживающих',
				'Психиатрическая больница (стационар) специализированного типа',
				'Психиатрическая больница (стационар) специализированного типа с интенсивным наблюдением',
				'Клинико-диагностическая лаборатория',
				'Бактериологическая больница, в том числе по диагностике туберкулеза',
				'Центр медицинской профилактики',
				'Центр медицины катастроф',
				'Центр медицинских мобилизационных резервов «Резерв»',
				'Медицинский информационно-аналитический центр',
				'Медицинский биофизический центр',
				'Центр военно-врачебной экспертизы',
				'Центр судебно-медицинской экспертизы',
				'Бюро медико-социальной экспертизы',
				'Бюро медицинской статистики',
				'Патологоанатомическое бюро',
				'Бюро судебно-медицинской экспертизы',
				'Центр гигиены и эпидемиологии',
				'Противочумный центр (станция)',
				'Дезинфекционный центр (станция)',
				'Центр гигиенического образования населения',
				'Центр государственного санитарно-эпидемиологического надзора'
			]
		};
	},
	watch: {
		'infoMO.name': function (newVal, oldVal) {
			// console.log(newVal, oldVal);
			if (newVal && oldVal) {
				let shortened = this.shortenName(newVal);
				if (shortened !== newVal) {
					this.infoMO.shortName = shortened;
				}
			}
		}
	},
	computed: {
		...getters
	},
	methods: {
		...mutations,
		...actionsLocal,
		...methods,
		getSystemYearData: actions.getSystemYearData,
		switchFilterVisibility(key) {
			this.filterData = {
				sortBy: 'asc',
				searchValue: ''
			};
			this.filterOpen = this.filterOpen === key ? null : key;
			this.filterBy = key;
		},
		setBranch(branch) {
			this.infoMO.isBranch = branch;
			this.infoMO.isClone = !branch;
			this.infoMO.typeName = branch ? 'Филиал' : 'Вторичная МО';
			this.branchDialog = false;

			if (!branch) {
				let beyondTheRegionNote = this.notes.find((x) => x.value === Note.BeyondTheRegion);

				if (beyondTheRegionNote !== undefined) {
					this.infoMO.noteId = beyondTheRegionNote.value;
					this.infoMO.years = [];
				}
			}
		},
		setInn(inn) {
			if (inn.length >= 10) {
				this.getMedicalOrgsByINN(inn).then((res) => {
					let mainMO = res.data.find((x) => x.isMain);

					if (mainMO) {
						if (this.originalMO && this.originalMO.isMain && this.originalMO.hasBranch) {
							this.openEditDialog(this.originalMO);
							this.headExistsDialog = true;
						} else {
							this.setMainMO(mainMO);
							this.innEmpty = false;
							this.branchDialog = true;
						}
					} else {
						let currentId = this.infoMO.id;
						this.resetForm();
						this.infoMO.id = currentId;
						this.infoMO.inn = inn;
						this.innEmpty = false;
					}
				});
			} else {
				let currentId = this.infoMO.id;
				this.resetForm();
				this.infoMO.id = currentId;
				this.infoMO.inn = inn;
			}
		},
		setMainMO(mainMO) {
			let moId = this.infoMO.id;
			let regionId = this.infoMO.regionId;
			Object.assign(this.infoMO, mainMO);
			this.infoMO.id = moId;
			this.infoMO.oldId = 0;
			this.infoMO.typeName = null;
			this.infoMO.parentId = mainMO.id;
			this.infoMO.parentName = mainMO.name;
			this.infoMO.isMain = false;
			this.infoMO.regionId = regionId;
			this.infoMO.address = null;
		},
		setName(id, type) {
			let name = '';
			this[type].forEach((item) => {
				if (item.value === id) name = item.name;
			});

			return name;
		},
		openConsole(text) {
			console.log(text);
		},
		notesChange(id) {
			this.noteDisabled = false;
			this.nomenclatures.forEach((item) => {
				if (item.value === id) {
					this.defNomenclatures.forEach((name) => {
						if (name === item.name) {
							this.infoMO.noteId = Note.NoParticipationByOrder;
							this.noteDisabled = true;
						}
					});
				}
			});
		},
		openPopUp() {
			this.infoMO.regionId = this.getAuthUser.regionId;
			this.resetFormErrors();
			this.update = false;
			this.popupTitle = 'Добавить МО';
			this.open = true;
		},
		changeRegion(regionId, resetYears) {
			this.optionsYears = [];
			this.loadingYears = true;
			if (resetYears) this.infoMO.years = [];

			this.$http.get(`Region/GetRegionYearData?regionId=${regionId}`).then((res) => {
				for (let i = 0; i < res.data.availableYears.length; i += 1) {
					this.optionsYears.push({ year: res.data.availableYears[i] });
				}
				for (let j = 0; j < res.data.closedYears.length; j += 1) {
					for (let l = 0; l < this.optionsYears.length; l += 1) {
						if (res.data.closedYears[j] === this.optionsYears[l].year) {
							this.optionsYears[l].$isDisabled = true;
						}
					}
				}

				this.loadingYears = false;
			});
		},
		openEditDialog(item) {
			if (item.regionId) {
				this.changeRegion(item.regionId, false);
			}

			this.originalMO = item;
			Object.assign(this.infoMO, item);

			if (this.infoMO.parentId !== null) {
				this.infoMO.typeName = this.infoMO.isBranch ? 'Филиал' : 'Вторичная МО';
				let parent = this.medOrgs.find((x) => x.id === this.infoMO.parentId);
				this.infoMO.parentName = parent ? parent.name : null;
			}

			if (item.inn) {
				this.innEmpty = false;
			} else {
				this.innEmpty = true;
			}

			this.notesChange(item.nomenclatureId);
			this.resetFormErrors();
			this.popupTitle = 'Редактировать МО';
			this.open = true;
			this.update = true;
		},
		sendEmail(login) {
			let yea = confirm('Отправить подтверждение на E-mail?');
			if (yea) {
				this.sendEmailConfirmedCode(login);
			}
			/* eslint-enable */
		},
		closePopup() {
			this.open = false;
			this.resetForm();
		},
		resetForm() {
			this.innEmpty = true;

			this.infoMO = {
				name: null,
				regionId: this.getAuthUser.regionId,
				shortName: null,
				address: null,
				postAddress: null,
				ownerShipId: null,
				inn: null,
				isMain: true,
				isBranch: false,
				isClone: false,
				typeName: 'Головная МО',
				webSiteAddress: null,
				departmentBelongId: null,
				nomenclatureId: null,
				noteId: null,
				ambulator: false,
				stacionar: false,
				healthResort: false,
				psychiatric: false,
				parentId: null,
				parentName: null,
				yearStr: null,
				years: []
			};

			this.originalMO = null;
		},
		getRowClass(item, opened, cell) {
			let result = '';

			if (item.noteId === Note.Reorganized) {
				result += 'bg-aquamarine';
			} else if (item.noteId === Note.Excluded) {
				result += 'bg-lilac';
			} else if (item.noteId === Note.NoTariffAgreement) {
				result += 'bg-rich-pink';
			} else if (item.noteId === Note.NoMedicalCare) {
				result += 'bg-light-green';
			} else if (item.noteId === Note.NoParticipationByOrder) {
				result += 'bg-lemon-yellow';
			} else {
				result += 'bg-white';
			}

			if (opened) {
				if (result.search('bg-white') !== -1) {
					result = result.replace('bg-white', 'bg-gray-30');
				} else if (!cell) {
					result += ' bg-dark-10';
				}
			}

			return result;
		},
		openRow(item) {
			if (item.hasBranch) {
				if (this.opened === item.id) {
					this.opened = null;
				} else {
					this.opened = item.id;
				}
			}
		},
		yearsAreAvailable(noteId) {
			return noteId === Note.Participation || noteId === Note.Federal;
		},
		resetFormErrors() {
			this.error = {
				name: null,
				regionId: null,
				shortName: null,
				address: null,
				postAddress: null,
				ownerShipId: null,
				inn: null,
				webSiteAddress: null,
				nomenclatureId: null,
				noteId: null
			};
		},
		getFormErrors(infoMO) {
			this.resetFormErrors();

			if (infoMO.inn === null || infoMO.inn.length < 10) {
				this.error.inn = 'Введите ИНН';
				return true;
			}

			if (!infoMO.name) {
				this.error.name = 'Введите полное наименование';
			}
			if (!infoMO.shortName) {
				this.error.shortName = 'Введите краткое наименование';
			}
			if (!infoMO.regionId) {
				this.error.regionId = 'Выберите регион';
			}
			if (!infoMO.address && !infoMO.isClone) {
				this.error.address = 'Введите фактический адрес';
			}
			if (!infoMO.postAddress) {
				this.error.postAddress = 'Введите почтовый адрес';
			}
			if (!infoMO.ownerShipId) {
				this.error.ownerShipId = 'Выберите форму собственности';
			}
			if (!infoMO.webSiteAddress) {
				this.error.webSiteAddress = 'Введите адрес официального сайта';
			}
			if (!infoMO.nomenclatureId) {
				this.error.nomenclatureId = 'Выберите номенклатуру';
			}
			if (!infoMO.noteId) {
				this.error.noteId = 'Выберите примечание';
			}
			if ((infoMO.noteId === Note.Participation || infoMO.noteId === Note.Federal) && !infoMO.ambulator && !infoMO.stacionar && !infoMO.healthResort && !infoMO.psychiatric) {
				this.error.conditions = 'Установите значение "Да" хотя бы в одном из условий оказания медицинской помощи';
			}
			let errors = Object.values(this.error).some((item) => {
				return item !== null;
			});
			return errors;
		},
		sendForm() {
			if (this.getFormErrors(this.infoMO)) {
				return;
			}

			if (!this.update) {
				this.addMO(this.infoMO).then((res) => {
					this.infoMO.id = res.data.id;

					let addedMO = {};
					Object.assign(addedMO, this.infoMO);

					if (this.infoMO.isMain) {
						this.medOrgs.unshift(addedMO);
						this.allRows.unshift(addedMO);
					} else {
						let mainMO = this.medOrgs.find((x) => x.id === this.infoMO.parentId);

						if (mainMO) {
							mainMO.hasBranch = true;

							if (mainMO.branches === undefined || mainMO.branches === null) {
								mainMO.branches = [];
							}

							if (this.opened !== mainMO.id) {
								this.openRow(mainMO);
							}

							mainMO.branches.unshift(addedMO);
						}
					}

					this.closePopup();
				});
			} else {
				this.updateMO(this.infoMO).then((res) => {
					if (res.errors) {
						console.log(res.errors);
					} else {
						let updatedMO = null;

						if (this.infoMO.isMain) {
							updatedMO = this.medOrgs.find((x) => x.id === this.infoMO.id);
							this.loadBranches(updatedMO);
						} else {
							let mainMO = this.medOrgs.find((x) => x.id === this.infoMO.parentId);

							if (mainMO) {
								updatedMO = mainMO.branches.find((x) => x.id === this.infoMO.id);
							} else {
								updatedMO = this.medOrgs.find((x) => x.id === this.infoMO.id);
							}
						}

						if (updatedMO) {
							Object.assign(updatedMO, this.infoMO);
							updatedMO.hasBranch = this.infoMO.hasBranch;
						}

						this.closePopup();
					}
				});
			}
		},
		loadBranches(medOrg) {
			let params = {
				filters: [
					{
						fieldName: 'ParentId',
						filterType: 1,
						filterValueType: 1,
						value1: medOrg.id
					}
				]
			};

			this.getMedicalOrgs(params).then((result) => {
				medOrg.branches = result.data.data;
			});
		},
		openDeleteDialog(item, index) {
			if (confirm('Вы уверены, что хотите удалить?')) {
				this.deleteMO(item.id)
					.then((result) => {
						if (item.isMain) {
							this.medOrgs.splice(index, 1);

							let rowIndex = this.allRows.findIndex((x) => x.id === item.id);
							this.allRows.splice(rowIndex, 1);
						} else {
							let mainMO = this.medOrgs.find((x) => x.id === item.parentId);

							if (mainMO) {
								mainMO.branches.splice(index, 1);

								if (mainMO.branches.length === 0) {
									mainMO.hasBranch = false;
								}
							}
						}

						this.notify('Медицинская организация успешно удалена!', 'success');
					})
					.catch((error) => {
						this.notify('Ошибка при удалении медицинской организации, обратитесь к Администратору!', 'error');
					});
			}
		},
		updateRow(rowsVisible) {
			this.rowsVisible = rowsVisible;
			this.getData(this.params, true);
		},
		openBunnersPopUp(item) {
			if (item.oldId) {
				this.bunnersId = item.oldId;
			} else {
				this.bunnersId = item.id;
			}
			this.openBunners = true;
		},
		closeBunners() {
			this.bunnersId = '';
			this.openBunners = false;
		},
		getData(params, resetPage) {
			if (resetPage) {
				this.params.currentPage = 0;
				this.page = 0;
				this.loadingTable = true;

				this.addManualSearchByName(this.params.filters);

				this.getMedicalOrgs(this.params).then((res) => {
					this.allRows = res.data.data;
					this.medOrgs = this.getTablePage(this.allRows, this.params.currentPage, this.rowsVisible);
					this.fields[0].list = res.data.searchData;
					this.fields[1].list = res.data.searchData;

					this.totalRows = res.data.totalRecords;
					this.totalCount = this.rowsVisible ? Math.ceil(res.data.totalRecords / this.rowsVisible) : 1;
					this.loadingTable = false;
				});
			} else {
				this.medOrgs = this.getTablePage(this.allRows, this.params.currentPage, this.rowsVisible);
			}
		},
		navigation(name, page) {
			this.page = page;
			this[name].currentPage = page;

			this.getData(this[name], false);
		},
		downloadExcell(params, fieldsDownloadExcell) {
			this.loadToExcell = true;
			let columnList = fieldsDownloadExcell.filter((f) => f.checked).map((m) => m.id);

			this.$http
				.post(
					'MedicalOrgs/GetMedicalOrgsExcel',
					{
						...params,
						columns: columnList
					},
					{
						responseType: 'blob',
						headers: {
							'Access-Control-Expose-Headers': 'Content-Disposition'
						}
					}
				)
				.then((res) => {
					const url = window.URL.createObjectURL(new Blob([res.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', 'НОК_Медицинские организации.xlsx');
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
					this.loadToExcell = false;
					this.closeOpenExport();
				})
				.catch((error) => {
					if (error) {
						this.loadToExcell = false;
						this.showMessages('Ошибка выгрузки, обратитесь к Администратору!', 'error');
					}
				});
		},
		downloadQr(item) {
			let type = 'ambulator';
			if (item.ambulator) {
				type = 'ambulator';
			} else if (item.stacionar) {
				type = 'stacionar';
			} else if (item.healthResort) {
				type = 'healthResort';
			} else {
				type = 'psychiatric';
			}
			this.getQr(item.id, type).then((res) => {
				let fileNameAndType = decodeURI('QR.png');

				const url = window.URL.createObjectURL(new Blob([res.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', fileNameAndType);
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			});
		},
		shortenName(name) {
			let regex = /Федеральное государственное образовательное учреждение высшего профессионального образования/i;
			name = name.replace(regex, 'ФГОУ ВПО');
			regex = /Федеральное государственное автономное образовательное учреждение высшего образования/i;
			name = name.replace(regex, 'ФГАОУ ВО');
			regex = /Федеральное государственное бюджетное образовательное учреждение высшего образования/i;
			name = name.replace(regex, 'ФГБОУ ВО');
			regex = /САНКТ-ПЕТЕРБУРГСКОЕ ГОСУДАРСТВЕННОЕ БЮДЖЕТНОЕ УЧРЕЖДЕНИЕ ЗДРАВООХРАНЕНИЯ/i;
			name = name.replace(regex, 'СПб ГБУЗ');
			regex = /Республиканское государственное бюджетное учреждение здравоохранения/i;
			name = name.replace(regex, 'РГБУЗ');
			regex = /Федеральное государственное бюджетное учреждение здравоохранения/i;
			name = name.replace(regex, 'ФГБУЗ');
			regex = /Федеральное государственное казенное учреждение здравоохранения/i;
			name = name.replace(regex, 'ФГКУЗ');
			regex = /Федеральное государственное казённое учреждение здравоохранения/i;
			name = name.replace(regex, 'ФГКУЗ');
			regex = /Областное государственное автономное учреждение здравоохранения/i;
			name = name.replace(regex, 'ОГАУЗ');
			regex = /осударственное областное бюджетное учреждение здравоохранения/i;
			name = name.replace(regex, 'ГОБУЗ');
			regex = /Областное государственное бюджетное учреждение здравоохранения/i;
			name = name.replace(regex, 'ОГБУЗ');
			regex = /Областное государственное казённое учреждение здравоохранения/i;
			name = name.replace(regex, 'ОГКУЗ');
			regex = /Областное государственное казенное учреждение здравоохранения/i;
			name = name.replace(regex, 'ОГКУЗ');
			regex = /Краевое государственное автономное учреждение здравоохранения/i;
			name = name.replace(regex, 'КГАУЗ');
			regex = /Краевое государственное бюджетное учреждение здравоохранения/i;
			name = name.replace(regex, 'КГБУЗ');
			regex = /Федеральное государственное бюджетное научное учреждение/i;
			name = name.replace(regex, 'ФГБНУ');
			regex = /Федеральное государственное бюджетное учреждение науки/i;
			name = name.replace(regex, 'ФГБУН');
			regex = /Федеральное государственное учреждение здравоохранения/i;
			name = name.replace(regex, 'ФГУЗ');
			regex = /Государственное автономное учреждение здравоохранения/i;
			name = name.replace(regex, 'ГАУЗ');
			regex = /Государственное бюджетное учреждение здравоохранения/i;
			name = name.replace(regex, 'ГБУЗ');
			regex = /Государственное казенное учреждение здравоохранения/i;
			name = name.replace(regex, 'ГКУЗ');
			regex = /Государственное казённое учреждение здравоохранения/i;
			name = name.replace(regex, 'ГКУЗ');
			regex = /Муниципальное автономное учреждение здравоохранения/i;
			name = name.replace(regex, 'МАУЗ');
			regex = /Муниципальное бюджетное учреждение здравоохранения/i;
			name = name.replace(regex, 'МБУЗ');
			regex = /Федеральное государственное унитарное предприятие/i;
			name = name.replace(regex, 'ФГУП');
			regex = /Федеральное государственное автономное учреждение/i;
			name = name.replace(regex, 'ФГАУ');
			regex = /Федеральное бюджетное учреждение здравоохранения/i;
			name = name.replace(regex, 'ФБУЗ');
			regex = /Федеральное государственное бюджетное учреждение/i;
			name = name.replace(regex, 'ФГБУ');
			regex = /Федеральное государственное казенное учреждение/i;
			name = name.replace(regex, 'ФГКУ');
			regex = /Федеральное государственное казённое учреждение/i;
			name = name.replace(regex, 'ФГКУ');
			regex = /Федеральное казенное учреждение здравоохранения/i;
			name = name.replace(regex, 'ФКУЗ');
			regex = /Федеральное казённое учреждение здравоохранения/i;
			name = name.replace(regex, 'ФКУЗ');
			regex = /Областное бюджетное учреждение здравоохранения/i;
			name = name.replace(regex, 'ОБУЗ');
			regex = /Государственное областное казенное учреждение/i;
			name = name.replace(regex, 'ГОКУ');
			regex = /Государственное областное казённое учреждение/i;
			name = name.replace(regex, 'ГОКУ');
			regex = /Негосударственное учреждение здравоохранения/i;
			name = name.replace(regex, 'НУЗ');
			regex = /Краевое государственное бюджетное учреждение/i;
			name = name.replace(regex, 'КГБУ');
			regex = /Государственное учреждение здравоохранения/i;
			name = name.replace(regex, 'ГУЗ');
			regex = /Муниципальное учреждение здравоохранения/i;
			name = name.replace(regex, 'МУЗ');
			regex = /Общество с ограниченной ответственностью/i;
			name = name.replace(regex, 'ООО');
			regex = /Федеральное бюджетное учреждение науки/i;
			name = name.replace(regex, 'ФБУН');
			regex = /Федеральное государственное учреждение/i;
			name = name.replace(regex, 'ФГУ');
			regex = /Автономная некоммерческая организация/i;
			name = name.replace(regex, 'АНО');
			regex = /Государственное бюджетное учреждение/i;
			name = name.replace(regex, 'ГБУ');
			regex = /Бюджетное учреждение здравоохранения/i;
			name = name.replace(regex, 'БУЗ');
			regex = /Казенное учреждение здравоохранения/i;
			name = name.replace(regex, 'КУЗ');
			regex = /Казённое учреждение здравоохранения/i;
			name = name.replace(regex, 'КУЗ');
			regex = /Муниципальное автономное учреждение/i;
			name = name.replace(regex, 'МАУ');
			regex = /Муниципальное унитарное предприятие/i;
			name = name.replace(regex, 'МУП');
			regex = /Государственное казенное учреждение/i;
			name = name.replace(regex, 'ГКУ');
			regex = /Государственное казённое учреждение/i;
			name = name.replace(regex, 'ГКУ');
			regex = /Муниципальное бюджетное учреждение/i;
			name = name.replace(regex, 'МБУ');
			regex = /Федеральное бюджетное учреждение/i;
			name = name.replace(regex, 'ФБУ');
			regex = /Закрытое акционерное общество/i;
			name = name.replace(regex, 'ЗАО');
			regex = /Открытое акционерное общество/i;
			name = name.replace(regex, 'ОАО');
			regex = /Государственное учреждение/i;
			name = name.replace(regex, 'ГУ');
			regex = /Бюджетное учреждение/i;
			name = name.replace(regex, 'БУ');
			regex = /Акционерное общество/i;
			name = name.replace(regex, 'АО');
			regex = /Казенное учреждение/i;
			name = name.replace(regex, 'КУ');
			regex = /Казённое учреждение/i;
			name = name.replace(regex, 'КУ');

			return name;
		},
		showMessages(text, type) {
			this.$toast[type](text, {
				position: 'top-right',
				timeout: 5000,
				closeOnClick: true,
				pauseOnFocusLoss: true,
				pauseOnHover: true,
				draggable: true,
				draggablePercent: 0.6,
				showCloseButtonOnHover: false,
				hideProgressBar: false,
				closeButton: 'button',
				icon: true,
				rtl: false
			});
		},
		closeOpenExport() {
			for (let i = 0; i < this.fieldsDownloadExcell.length; i += 1) {
				this.fieldsDownloadExcell[i].checked = true;
			}

			this.openExport = false;
		}
	},
	created() {
		this.Note = Note;
	},
	mounted() {
		this.resetForm();

		this.params.sortOption.fieldName = this.defaultSort.field;
		this.params.sortOption.sortType = this.defaultSort.order;

		this.getData(this.params, true);
		this.resetFormErrors();

		this.getRegions().then((res) => {
			this.fields[2].list = res.data;
			this.regions = [];
			res.data.forEach((item) => {
				this.regions.push({ name: item.Name, value: item.Id });
			});
		});
		this.getDepartmentBelong().then((res) => {
			this.fields[8].list = res.data;
			this.departments = [];
			res.data.forEach((item) => {
				this.departments.push({ name: item.name, value: item.Id });
			});
		});
		this.getNomenclatures().then((res) => {
			this.fields[9].list = res.data;
			this.nomenclatures = [];
			res.data.forEach((item) => {
				this.nomenclatures.push({ name: item.name, value: item.Id });
			});
		});
		this.getNotes().then((res) => {
			this.fields[10].list = res.data;
			this.notes = [];
			res.data.forEach((item) => {
				this.notes.push({ name: item.name, value: item.Id });
			});
		});
		this.getOwnership().then((res) => {
			this.fields[5].list = res.data;
			this.ownership = [];
			res.data.forEach((item) => {
				this.ownership.push({ name: item.name, value: item.Id });
			});
		});

		// this.getSystemYearData().then((result) => {
		// 	let yearField = this.fields.find((x) => x.fieldName === 'YearStr');

		// 	if (yearField !== undefined) {
		// 		yearField.filterOptions = result.data.availableYears.map((x) => {
		// 			return { name: x.toString(), checked: false };
		// 		});
		// 	}
		// });
	},
	beforeUpdate() {
		document.documentElement.style.setProperty('--scrollbar-width', `${window.innerWidth - document.documentElement.clientWidth}px`);
	}
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
.tr1__sticky {
	background-color: #f9fafb;
	top: 0;
	position: sticky;
	z-index: 10;
}
.sticky-table__header {
	left: 40px;
	z-index: 9;
	position: sticky;
	min-width: 500px;
	max-width: 500px;
	width: 500px;
	background-color: #f9fafb;
}
.sticky-table__row {
	min-width: 500px;
	max-width: 500px;
	width: 500px;
	left: 40px;
	position: sticky;
}
.sticky-table__header2 {
	left: 0;
	z-index: 9;
	position: sticky;
	background-color: #f9fafb;
}
.sticky-table__row2 {
	z-index: 9;
	left: 0;
	position: sticky;
}

.icon-control {
	cursor: pointer;
}
</style>

<style>
.preloader-years {
	top: 0;
	z-index: 10;
	background: rgba(0, 0, 0, 0.2);
}

.preloader {
	z-index: 10;
	background: rgba(0, 0, 0, 0.09);
}

.row-w {
	min-width: 200px;
	max-width: 200px;
	width: 200px;
}

.row-w-2 {
	min-width: 350px;
	max-width: 350px;
	width: 350px;
}

.custom-w-table-144 {
	max-width: calc(100vw - var(--scrollbar-width) - 144px - 3rem);
}

.custom-w-table-360 {
	max-width: calc(100vw - var(--scrollbar-width) - 360px - 3rem);
}
.td-name {
	position: absolute;
	background: #fff;
	width: 30%;
	left: 0;
	margin-top: -1px;
	z-index: 10;
}

.table-mo {
	padding-left: 30%;
}

.table-mo:after {
	content: '';
	position: absolute;
	left: 0;
	height: calc(100% - 112px);
	top: 0;
	width: 30%;
	z-index: 8;
	background: #fff;
}

.select-label {
	font-weight: 700;
	font-size: 14px;
	display: block;
	line-height: 18px;
	margin-bottom: 12px;
	color: #a8b1ce;
}

.radio-group {
	margin-bottom: 20px;
}

.multiselect__spinner {
	height: 50px;
}

.multiselect__tags {
	font-weight: 400;
	font-size: 14px;
	line-height: 50px;
	min-height: 50px;
	width: 100%;
	border-radius: 10px;
	border: 1px solid #a8b1ce;
	padding: 0 20px;
	color: #6a6e83;
}
.multiselect__tags-wrap {
	display: flex;
	align-items: center;
	height: 50px;
}
.multiselect__option--highlight:after {
	display: none;
}
.multiselect__select {
	height: 50px;
}
.label {
	align-items: center;
	cursor: pointer;
}
.label:hover .label-text {
	text-decoration: none;
}
.label-text {
	text-align: center;
	font-weight: 700;
	font-size: 14px;
	line-height: 18px;
	text-decoration-line: underline;
	color: #3377ff;
}
.ava {
	background: #f2f5ff;
	border-radius: 50%;
	width: 80px;
	overflow: hidden;
	margin-right: 20px;
	height: 80px;
	display: flex;
	background-size: cover;
	background-position: 50% 50%;
}
.ava .icon,
.ava img {
	margin: auto;
}

.t-overflow {
	overflow: auto;
}

li.arrow::before {
	@apply block w-2 h-2 mt-1.5 mr-2 border-b-2 border-l-2 border-gray-400;
	content: '';
	transform: rotate(45deg);
}
.arrow:last-child::before {
	transform: rotate(-135deg);
}

.input-box {
	position: relative;
	margin-bottom: 10px;
}

.input-box label {
	font-weight: 700;
	font-size: 14px;
	display: block;
	line-height: 18px;
	margin-bottom: 12px;
	color: #a8b1ce;
}

.static-row {
	position: relative;
	margin-bottom: 10px;
}

.static-row label {
	font-weight: 700;
	font-size: 14px;
	line-height: 18px;
	margin-bottom: 12px;
	color: #a8b1ce;
}

.field-value {
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	padding: 0 5px;
	color: #6a6e83;
}

.t-overflow::-webkit-scrollbar {
	width: 11px; /* ширина всей полосы прокрутки */
}

.t-overflow::-webkit-scrollbar-track {
	background: rgba(168, 177, 206, 0.3); /* цвет зоны отслеживания */
}

.t-overflow::-webkit-scrollbar-thumb {
	background: #3377ff;
	border-radius: 10px;
}

.error-text {
	color: #e94b3d;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
}

.error .vs__dropdown-toggle,
.error .multiselect__tags {
	border: 1px solid #e94b3d;
}
</style>
