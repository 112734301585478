<template>
	<div class="bunner">
		<iframe :src="url + level + '/GetBannerNok/' + ids + '/' + type" border="0" scrolling="no" allowtransparency="true" :width="framewidth" :height="frameheight" style="border: 0"></iframe>
		<p>Код для вставки баннера</p>
		<p class="whitespace-pre-line">
			&lt;iframe src="{{ url }}{{ level }}/GetBannerNok/{{ ids }}/{{ type }}" border="0" scrolling="no" allowtransparency="true" width="{{ framewidth }}" height="{{ frameheight }}"
			style="border: 0;">&lt;/iframe>
		</p>
		<a target="_blank" :href="`${url}${level}/GetBannerNok/${ids}/${type}`">
			<Button text="Получить пример" />
		</a>
	</div>
</template>

<script>
import Button from '@/components/Button.vue';

export default {
	name: 'Bunner',
	components: { Button },
	props: {
		ids: String,
		type: String,
		level: String
	},
	data() {
		return {
			url: 'https://nok.minzdrav.gov.ru/'
		};
	},
	computed: {
		framewidth: function () {
			if (this.type === '2') {
				return 120;
			}
			return 300;
		},
		frameheight: function () {
			if (this.type === '1') {
				return 110;
			}
			if (this.type === '2') {
				return 260;
			}
			if (this.type === '3') {
				return 200;
			}
			return 0;
		}
	}
};
</script>

<style scoped>
.bunner {
	padding: 30px 40px;
	width: 32%;
	background: #ffffff;
	border-radius: 10px;
	box-shadow: 0px 4px 17px rgba(171, 184, 225, 0.4);
}

.bunner p {
	margin-bottom: 10px;
	font-weight: 600;
	font-size: 14px !important;
	line-height: 24px;
	color: #6a6e83;
}

.bunner a {
	word-break: break-all;
	font-size: 14px !important;
	color: #3377ff;
}

.bunner p + p {
	margin-bottom: 20px;
}
</style>
