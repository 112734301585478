export const Note = Object.freeze({
	Participation: '150a6a2c-e499-435d-9c2c-8488e60f5555',
	NoParticipationByOrder: '0f366d7c-e474-4ae3-a2b9-05164be8ef1f',
	NoMedicalCare: '26d768c1-1a3b-4577-a2bf-fe05dd434ce9',
	NoTariffAgreement: '943ba04e-3af7-4114-bbd7-f3db471adfb8',
	Federal: '789200c6-8ddd-4dcd-abe5-3e3ab5e9ad7f',
	Excluded: '0ee7a191-9272-4c59-b161-3c73d21e9387',
	Reorganized: '0f7c1592-e6ef-460d-92fd-0c4932cfdb42',
	BeyondTheRegion: '19286ca5-1e3f-45c4-a72d-aeb172cbe7ff'
});
