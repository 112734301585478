<template>
	<div class="input-box" :class="{ error: error }" :style="label.length === 0 ? 'margin-bottom: 0' : ''">
		<label v-if="label.length > 0" :for="_uid">{{ label }}</label>
		<input
			:style="height > 0 ? `height: ${height}px` : ''"
			:placeholder="placeholder"
			v-bind="$attrs"
			:type="type"
			:id="_uid"
			:disabled="disabled"
			:class="{ disabled: disabled }"
			@change="$emit('change')"
			:value="value"
			@input="updateInput"
		/>
		<p v-if="error" class="error-text">{{ error }}</p>
	</div>
</template>

<script>
export default {
	name: 'TextInput',
	props: {
		label: {
			type: String,
			default: ''
		},
		disabled: Boolean,
		type: {
			type: String,
			default: 'text'
		},
		value: [Number, String, Date],
		error: String,
		placeholder: String,
		height: Number
	},
	methods: {
		updateInput(e) {
			this.$emit('input', e.target.value);
		}
	}
};
</script>

<style scoped>
.input-box {
	position: relative;
	margin-bottom: 10px;
}

.input-box label {
	font-weight: 700;
	font-size: 14px;
	display: block;
	line-height: 18px;
	margin-bottom: 12px;
	color: #a8b1ce;
}

.input-box input {
	font-weight: 400;
	font-size: 14px;
	height: 50px;
	min-width: 60px;
	line-height: 50px;
	width: 100%;
	border-radius: 10px;
	border: 1px solid #a8b1ce;
	padding: 0 20px;
	color: #6a6e83;
}

.error.input-box input {
	border-color: #e94b3d;
}

.error-text {
	color: #e94b3d;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
}

.input-box input:focus {
	border-color: #3377ff;
	outline: 0;
}

.input-box input.disabled {
	background: #f2f5ff;
}
</style>
